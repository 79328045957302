.chat-input-wrapper{
  position: fixed;
  z-index: 1200;
  background-color: #fff;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 10px 45px;
}
.chat-input-box {
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
}
.chat-input{
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}
.chat-input input{
  width: 100%;
  border: 0;
  outline: 0;
  -webkit-appearance: none;
  background-color: transparent;
  font-size: inherit;
  color: inherit;
}
.emoji-box{
  position: absolute;
  left: 0px;
  width: 100%;
  height: 100%;
  bottom: 0px;
}
.emoji-box .emoji-icon{
  width: 32px;
  height: 32px;
  position: absolute;
  left: 10px;
  bottom: 12px;
}
.emoji-box .emoji-icon .emoji-icon-img{
  width: 100%;
}
.submit-box{
  width: 32px;
  height: 32px;
  position: absolute;
  right: 10px;
  bottom: 12px;
  background: linear-gradient(120deg,#3bacee,#32c4d3);
  box-shadow: -3px 2px 2px rgba(18,111,112,.2);
  border-radius: 50%;
}
.submit-box .submit-icon-img{
  width: 80%;
  margin: 0 auto;
  display: block;
  margin-top: 4px;
}
.emoji-box .emoji-picker{
  width: 100%;
  height: 210px;
  bottom: 62px;
  z-index: 2500;
  position: absolute;
  max-height: 260px;
  box-shadow: 0 1px 15px 1px rgba(0, 0, 0, 0.12);
  background-color: white;
  border-radius: 6px;
  display: none;
}
.emoji-box .emoji-picker .emoji-picker-arrow{
  position: absolute;
  bottom: -8px;
  left: 17px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid white;
}
.emoji-popup-animate-show{
  animation-name: emoji-fadeInUp;
  animation-duration: 0.4s;
  animation-fill-mode: both;
  display: block !important;
}
.chat-textarea-box{
  width: 100%;
  height: 100%;
  position: relative;
  min-height: 100%;
  border-radius: 5px;
  overflow: hidden;
  box-sizing: border-box;
}
.chat-input-wrapper{
  box-sizing: border-box;
}
.chat-input-wrapper .chat-input{
  width: 100%;
  margin: 0px;
  padding: 0px 10px;
  color: #333;
  position: absolute;
  z-index: 9;
  height: 100%;
  line-height: 32px;
  font-size: 18px;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: #edf5f8;
  border: none;
  resize: none;
  outline: none;
  box-sizing: border-box;
}
.textareaPlaceholder{
  white-space:normal;
  word-break:break-all;
  overflow:hidden;
  visibility: hidden;
  position: relative;
  width: 100%;
  height: 100%;
  color: #333;
  line-height: 32px;
  font-size: 18px;
  max-height: 200px;
  padding: 0px 10px;
  box-sizing: border-box;
  margin: 0px;
}
.emoji-picker-content img{
  width: 100%;
}
.emoji-picker-content .emoji-item{
  float: left;
  width: 36px;
  height: 40px;
  padding: 4px;
  font-size: 31px;
  margin: 0px 2px;
  line-height: 31px;
  cursor: pointer;
}
.emoji-picker-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  overflow: auto;
}
#emoji-picker-content-warpper{
  padding: 5px 4px 10px 4px;
}
@keyframes emoji-fadeInUp {
  from {
    opacity: .2;
    transform: translate3d(0, 8%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}
.popup-animate-hide2{
  animation-name: fadeOutDown;
  animation-duration: 0.3s;
  animation-fill-mode: both;
  display: none;
}
@keyframes fadeOutDown {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.8;
    transform: translate3d(0, 100%, 0);
  }
}



/*messages*/
.message-item{
  padding: 10px 0px;
  position: relative;
  overflow: hidden;
}
.message-item .message-item-content{
  position: relative;
  background-color: #fff;
  display: inline-block;
  padding: 10px;
  border-radius: 3px;
  max-width: 80%;
  line-height: 1.35em;
  font-size: 18px;
  word-break: break-all;
  min-height: 36px;
  margin: 0px;
  box-sizing: border-box;
  font-size: .85em;
}
.message-item .message-item-content:before{
  content: '';
  display: block;
  position: absolute;
  top: 12px;
  width: 0;
  height: 0;
}
.message-item .message-item-avatar {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: absolute;
}
.message-item-other{
  padding-left: 50px;
}
.message-item-other .message-item-avatar{
  left: 5px;
}
.message-item-other .message-item-content{
  float: left;
  background-color: #d7e4f4;
  position: relative;
}
.message-item-own .message-item-content .error-status{
  width: 32px;
  position: absolute;
  left: -38px;
  top: 50%;
  margin-top: -16px;
  padding: 4px;
}
.message-item-own .message-item-content .error-status img{
  width: 100%;
  display: block;
}
.message-item-own{
  padding-right: 50px;
}
.message-item-own .message-item-avatar{
  right: 5px;
}
.message-item-own .message-item-content{
  float: right;
}
.message-item-own .message-item-content:before {
  right: -10px;
  border-right: 12px solid transparent;
  border-bottom: 12px solid white;
}
.message-item-other .message-item-content:before {
  left: -10px;
  border-left: 12px solid transparent;
  border-top: 12px solid #d7e4f4;
}
.message-item-info {
  width: 80%;
  font-size: .75em;
  color: #7a7a7a;
  display: flex;
  justify-content: space-between;
}
.message-content-emoji{
  width: 16px;
  display: inline-block;
  position: relative;
  top: 1px;
  margin: 0px 1px;
}
.massage-container {
  position: relative;
  background-color: #f0f2f5;
  box-sizing: border-box;
}
.message-list-wrapper {
  padding-top: 88px;
  padding-bottom: 20px;
  background-color: #f0f2f5;
  z-index: -9999;
}
.message-list-wrapper .time-info{
  margin: 10px 0px;
  text-align: center;
}
.message-list-wrapper .time-info span{
  background-color: rgba(0,0,0,.12);
  min-width: 90px;
  line-height: 14px;
  display: inline-block;
  margin: 0 auto;
  font-size: 13px;
  color: #fff;
  text-align: center;
  padding: 4px 8px;
  border-radius: 4px;
}
.noData-tips{
  text-align: center;
  color: #7a7a7a;
  font-size: 13px;
  padding: 10px 0px;
}
.unread-count-tips:before{
  display: block;
  content: '';
  position: absolute;
  bottom: -4px;
  left: 50%;
  width: 0;
  height: 0;
  margin-left: -8px;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #00a2ae;
}
.unread-count-tips{
  background-color: #00a2ae;
  color: #fff;
  padding: 5px 10px;
  position: absolute;
  bottom: 62px;
  right: 25px;
  border-radius: 14px;
  font-size: 14px;
}
/*loading animate*/
.message-loading{

}
.loadEffect{
  width: 100px;
  height: 100px;
  position: relative;
  margin: 0 auto;
  transform: scale(0.2, 0.2);
  margin-top: -30px !important;
  margin-bottom: -40px !important;
}
.loadEffect span{
  display: inline-block;
  width: 30px;
  height: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: #0a0a0a;
  position: absolute;
  opacity: 0.2;
  -webkit-animation: load-animation 1.04s ease infinite;
}
@-webkit-keyframes load-animation{
  0%{
    opacity: 1;
  }
  100%{
    opacity: 0.2;
  }
}
.loadEffect span:nth-child(1){
  left: 0;
  top: 50%;
  margin-top:-5px;
  -webkit-animation-delay:0.13s;
}
.loadEffect span:nth-child(2){
  left: 10px;
  top: 20px;
  -webkit-transform: rotate(45deg);
  -webkit-animation-delay:0.26s;
}
.loadEffect span:nth-child(3){
  left: 50%;
  top: 10px;
  margin-left: -15px;
  -webkit-transform: rotate(90deg);
  -webkit-animation-delay:0.39s;
}
.loadEffect span:nth-child(4){
  top: 20px;
  right:10px;
  -webkit-transform: rotate(135deg);
  -webkit-animation-delay:0.52s;
}
.loadEffect span:nth-child(5){
  right: 0;
  top: 50%;
  margin-top:-5px;
  -webkit-transform: rotate(180deg);
  -webkit-animation-delay:0.65s;
}
.loadEffect span:nth-child(6){
  right: 10px;
  bottom:20px;
  -webkit-transform: rotate(225deg);
  -webkit-animation-delay:0.78s;
}
.loadEffect span:nth-child(7){
  bottom: 10px;
  left: 50%;
  margin-left: -15px;
  -webkit-transform: rotate(270deg);
  -webkit-animation-delay:0.91s;
}
.loadEffect span:nth-child(8){
  bottom: 20px;
  left: 10px;
  -webkit-transform: rotate(315deg);
  -webkit-animation-delay:1.04s;
}

.live-header {
  position: fixed;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background-color: var(--tabbar-item-active-color);
  /* display: inline-block; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* vertical-align: middle; */
  color: #d7e4f4;
}


.za-search-bar {
  height: 40px;
  background-color: var(--tabbar-item-active-color);
}

.live-header .za-icon--md {
  color: #d7e4f4;
}
.live-title {
  flex: 24;
}
.search-icon {
  flex: 1;
  margin-right: 8px;
  text-align: right;
  /* display: fixed; */
  /* right: 4px; */
  /* padding-left: 46%; */
}
.live-ad {
  position: fixed;
  top: 40px;
  width: 100%;
}

.live-options {
  position: fixed;
  top: 84px;
}
.live-options-button {
  margin: 4px;
}

.message-list-wrapper {
  padding-top: 128px;
  margin-bottom: 60px;
}

.top {
  z-index: 999;
}

.za-message--danger {
  color: #d7e4f4;
  background-color: #333;
}
