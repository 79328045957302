.buy-list {
    padding-top: 48px;
}
.buy-item {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #f0f0f0;
    /* margin: 12px 0; */
    width: 100%;
    height: 120px;
}

.buy-item img {
    width: 120px;
    height: 120px;
}

.buy-msg-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 12px 12px;
}
.buy-msg {
    font-size: 13px;
    line-height: 16px;
}
.buy-price {
    font-size: 13px;
    font-weight: 600;
    color: #F00;
}