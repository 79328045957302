.discount-container {
    background-color: #fff;
}

.discount-list {
    padding: 48px 12px;
}
.discount-list div {
    margin: 12px 0;
    border-radius: 6px;
    width: 100%;
    overflow: hidden;
}

.discount-list div img {
    width: 100%;
    height: 96.5px;
}